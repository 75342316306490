import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import dateFormat from 'dateformat'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import ReactLoading from 'react-loading'
import Skeleton from '@mui/material/Skeleton'

import { detectOperation, getToApi, postToApi } from '../../functions/utilsFunctions'
import { useAuth } from '../context/auth'
import { toastify } from '../shared/toast/Toast'
import Skel from '../shared/skeleton/Skel'
import { Input } from '../shared/input/Input'

function Claims() {
    const [claims, setClaims] = useState([])
    const [claim, setClaim] = useState({})
    const [loading, setLoading] = useState('claims')
    const [modal, setModal] = useState(false)
    const myRef = React.useRef(null)
    useEffect(() => {
        getClaims()
        return () => {

        }
    }, [])

    const getClaims = async () => {
        const response = await getToApi('claim/getall')
        if (response.claims) setClaims(response.claims)
        else toastify('error', 'impossible de recuperer les reclammations')
        setLoading('')
    }

    const reload = async () => {
        await getClaims()
        setModal(false)
    }

    // const checkTransaction = async () => {
    //     setLoading('verif');
    //     setModal(true);
    //     let response = await postToApi('checktransaction', { id: tx.transaction_id, number: tx.phone, txid: tx.txid, crypto: tx.cryptoCurency, provider: tx.provider });
    //     // console.log("the response ", response);
    //     if (!response.success) {
    //         toastify('error', "Une erreur est survenu veuillez reesayer", 8000);
    //         return setModal(false);
    //     }
    //     setResult(response);
    //     setLoading(false);
    // }
    const select = (item) => {
        setClaim(item)
        setModal(true)
    }
    return (
        <div className='claims shadows' ref={myRef}>
            <h2>Liste des reclammations</h2>
            <div className="claims-liste">
                <div className="transaction-min">
                    <div><b>Date</b></div>
                    <div><b>Identifiant</b></div>
                    <div><b>Montant (XAF/Crypto)</b></div>
                    <div><b>Client</b></div>
                    <div><b>Operation</b></div>
                    <div><b>Statut</b></div>
                    <div><b>Actions</b></div>
                </div>
                {

                    loading == 'claims' ? <>
                        <Skel variant="rectangular" heigth={100} /><Skel variant="rectangular" heigth={100} /><Skel variant="rectangular" heigth={100} />
                    </>
                        : (claims.length ? claims.map((item, i) => <ClaimMin data={item} action={select} key={`claim-${i}`} />)
                            : <center><br /><h2>Aucune Reclammation initiée</h2><br /></center>)
                }
            </div>

            <Modal open={modal} center onClose={() => setModal(false)} container={myRef.current} >
                <HandleClaim data={claim} reload={reload} />
            </Modal>
        </div>
    )
}

const HandleClaim = ({ data, reload }) => {
    const [loading, setLoading] = useState('')
    const [modal, setModal] = useState(false)
    const User = useAuth().user
    const myRef = React.useRef(null)

    const close = async () => {
        setLoading('close')
        let response = await getToApi('claim/close/' + data._id)
        if (!response.success) toastify('error', "Echec")
        else {
            await reload()
            toastify('success', "Reclammation fermé")
        }
        setLoading('')
    }
    const refund = async (rest) => {
        setLoading('refund')
        const refund = {
            transaction_id: data.transaction_id,
            clientId: data.clientId,
            authorId: User.userId,
            authorName: User.userName,
            amount: data.amountFiat,
            amountCrypto: data.amountCrypto,
            currency: data.currency,
            claim_id: data._id,
            account: rest.account,
            receiver: rest.receiver || "",
            purpose: rest.purpose || "R.A.S",
            comment: rest.comment || "R.A.S",
        }
        console.log("the refund object  ", refund)
        let response = await postToApi('refund', refund)
        if (!response.success) toastify('error', "Echec du remboursement")
        else {
            await reload()
            toastify('success', "Rembousement effectué \n Reclammation modifiée")
        }
        setLoading('')
    }
    const choose = (data) => {
        refund(data)
        setModal(false)
    }
    const selectAccount = () => setModal(true)
    return (
        <div className="handle-claim" ref={myRef}>
            <div className="claim-operation">
                <h3>Details de l'operation</h3>
                <div>Status &ensp;:&ensp; &ensp; <b>{data.status}</b> </div>
                <div>Identifiant &ensp;:&ensp; &ensp; {data.transaction_id} </div>
                <div>Service &ensp;:&ensp; &ensp; {detectOperation(data.transaction_id)} </div>
                {/* <div>Cryptomonnaie &ensp;:&ensp; &ensp; {data.currency} </div> */}
                <div>Montant de crypto &ensp;:&ensp; &ensp; {`${data.amountCrypto} ${data.currency}`} </div>
                <div>Montant de XAF &ensp;:&ensp; &ensp; {data.amountFiat} </div>
                <div>Nom du client &ensp;:&ensp; &ensp; {data.clientName} </div>
            </div>
            <hr />
            <div className="claim-author">
                <h3>Details de la reclamation</h3>
                <div>Créée par &ensp;:&ensp; &ensp; {data.authorName} </div>
                <div>Créée le &ensp;:&ensp; &ensp; {dateFormat(data.created_at, 'dd/mm/yyyy, HH:MM')} </div>
            </div>
            <hr />
            <div className="claim-actions">
                {/* <button className='modal-button'>
                    Verifier la transaction
                </button> */}
                <button onClick={close} className='modal-button'>
                    {loading == 'close' ? <ReactLoading type="spin" color='#FFF' height={30} width={30} /> : 'Fermer la reclamation'}

                </button>
                <button onClick={selectAccount} className='modal-button'>
                    {loading == 'refund' ? <ReactLoading type="spin" color='#FFF' height={30} width={30} /> : 'Rembourser le client'}
                </button>
            </div>
            <Modal open={modal} center onClose={() => setModal(false)} container={myRef.current} >
                <ChooseAccount data={data} action={choose} />
            </Modal>
        </div>
    )
}


function ClaimMin({ data, action }) {
    const history = useHistory()
    return (
        <div className="transaction-min">
            <div><b>{dateFormat(data.created_at, "dd/mm/yyyy, HH:MM")}</b></div>
            <div className='tx-id' onClick={() => history.push('/transaction/:' + data.transaction_id)} >{data.transaction_id}</div>
            <div>{`${data.amountFiat} XAF/${data.amountCrypto} ${data.currency}`} </div>
            <div>{data.clientName}</div>
            <div>{detectOperation(data.transaction_id)}</div>
            <div>{data.status.toUpperCase()}</div>
            <div><button className='details' onClick={() => action(data)}>details</button></div>
        </div>

    )
}

const ChooseAccount = ({ data, action }) => {
    const [text, setText] = useState({ receiver: '', purpose: '', comment: '' })

    const handleChange = (e) => setText({ ...text, [e.target.name]: e.target.value })
    const handleClick = (account) => {
        action({ ...text, account: account })
    }
    const opType = detectOperation(data.transaction_id)
    let params
    switch (opType) {
        case "Rachat Crypto":
            params = {
                label: "Entrer le numero de telephone avec indicatif (Ex: +237)",
                placeHolder: "Laisser vide pour utiliser celui du client",
                bt1: "Utiliser Intouch",
                bt2: "Utiliser Cinetpay",
                bt1Value: 'intouch',
                bt2Value: 'cinetpay'
            }
            break;
        case "Vente Crypto":
            params = {
                label: "Entrer le wallet",
                placeHolder: "Laisser vide pour utiliser celui du client",
                bt1: "Utiliser le wallet de distribution",
                bt2: "Utiliser le wallet de reception",
                bt1Value: 'distribution',
                bt2Value: 'reception',
            }
            break;
        case "Transfert":

            break;
        default:
            break;
    }
    return (
        <div className="">
            <h3>{opType}</h3>
            <Input id="receiver" name='receiver' label={`${params.label} (${params.placeHolder})`} placeHolder={params.placeHolder}
                val={text.receiver} change={handleChange}
            />
            <Input id="purpose" name='purpose' label="Entrer l'objectif du rembousement" placeHolder="objectif du remboursement"
                val={text.purpose} change={handleChange}
            />
            <Input id="comment" name='comment' type='text' label="Entrer un commentaire"
                val={text.comment} change={handleChange}
            />
            <div className='claim-actions'>
                <button className='modal-button' onClick={() => handleClick(params.bt1Value)}>{params.bt1}</button>
                <button className='modal-button' onClick={() => handleClick(params.bt2Value)}>{params.bt2}</button>
            </div>
        </div>
    )
}


export default Claims