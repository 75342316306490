import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MatSelect from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Select({ label, name, id, options, val, change, error }) {
    const handleChange = (event) => {
        change(event.target);
    };

    return (
        <>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <MatSelect
                labelId="demo-simple-select-label"
                id={id}
                name={name}
                value={val}
                onChange={handleChange}
            >
                {
                    options.map(item => <MenuItem value={item.value}>{item.text}</MenuItem>)

                }
            </MatSelect>
        </>
    );
}
