import React from 'react'
import Balances from '../home/balances/Balances'
import Claims from './Claims'
import Refunds from './Refunds'

import './sav.css'

function Sav() {
    return (
        <div className="sav">
            <Balances />
            <Claims />
            <Refunds />
        </div>
    )
}

export default Sav