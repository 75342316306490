import React, { useState, useEffect, useRef } from 'react'
import ReactLoading from 'react-loading'
import { useFormik } from 'formik'
import * as yup from 'yup'


import { Input } from '../../shared/input/Input'
import { useAuth } from '../../context/auth'
import { toastify } from '../../shared/toast/Toast'
import { postToApi } from '../../../functions/utilsFunctions'


const validationSchema = yup.object({
    receiver: yup.string().required('Ce champ est requis'),
    amount: yup.number().moreThan(0, "Ce champ doit etre superieur à 0").required("Le montant est requis"),
    purpose: yup.string(),
    comment: yup.string(),
})

function SendAmount({ data, close, reload }) {
    const [loading, setLoading] = useState('')
    const User = useAuth().user
    const formik = useFormik({
        initialValues: {
            receiver: "",
            amount: 0,
            purpose: "",
            comment: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            setLoading('refund')
            // console.log("the values ", values)
            await refund(values)
            setLoading('')
        }
    })

    const refund = async (values) => {
        const refund = {
            authorId: User.userId,
            authorName: User.userName,
            amount: values.amount,
            currency: data.currency,
            account: data.account,
            receiver: values.receiver,
            purpose: values.purpose || "R.A.S",
            comment: values.comment || "R.A.S",
            type: data.currency == 'XAF' ? 'mobile' : 'crypto'
        }
        console.log("the refund object  ", refund)
        let response = await postToApi('adminrefund', refund)
        if (!response.success) toastify('error', "Echec du remboursement")
        else {
            close()
            reload()
            toastify('success', "Rembousement effectué \n Reclammation modifiée")
        }
    }

    let params
    if (data.currency == 'XAF') {
        params = {
            label: "Entrer le numero de telephone avec indicatif (Ex: +237xxxxxxxxx)",
            placeHolder: "Laisser vide pour utiliser celui du client",
            bt1: "Envoyer avec " + data.account,
            bt1Value: 'intouch',
        }
    } else {
        params = {
            label: "Entrer le wallet",
            placeHolder: "Laisser vide pour utiliser celui du client",
            bt1: "Envoyer avec wallet " + data.account,
            bt1Value: 'distribution',
        }
    }
    // console.log("the data ", data)
    return (
        <div className="send-amount" >
            <h3>Envoyer un montant</h3>
            <div className="input-group">
                <Input id="receiver" name='receiver' label={params.label} placeHolder={params.placeHolder}
                    val={formik.values.receiver} change={formik.handleChange} handBlur={formik.handBlur}
                    error={formik.errors.receiver}
                />
            </div>
            <div className="input-group">
                <Input id="amount" name='amount' type='number' label={"Entrer le montant en " + data.currency}
                    val={formik.values.amount} change={formik.handleChange} handBlur={formik.handBlur}
                    error={formik.errors.amount}
                />
            </div>
            <div className="input-group">
                <Input id="purpose" name='purpose' label="Entrer le motif du transfert" placeHolder="objectif du remboursement"
                    val={formik.values.purpose} change={formik.handleChange} handBlur={formik.handBlur}
                />
            </div>
            <div className="input-group">
                <Input id="comment" name='comment' type='text' label="Entrer un commentaire"
                    val={formik.values.comment} change={formik.handleChange} handBlur={formik.handBlur}
                />
            </div>
            <div className='send-actions'>
                <button onClick={formik.handleSubmit}>
                    {loading ? <ReactLoading type="spin" color='#FFF' height={30} width={30} /> : params.bt1}
                </button>
            </div>
        </div>
    )
}

export default SendAmount