import React, { useEffect, useState } from 'react'
import dateFormat from 'dateformat'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { useHistory } from 'react-router-dom'

import { detectOperation, getToApi, postToApi } from '../../functions/utilsFunctions'
import { toastify } from '../shared/toast/Toast'
import Skel from '../shared/skeleton/Skel'

function Refunds() {
    const [refunds, setRefunds] = useState([])
    const [refund, setRefund] = useState({})
    const [loading, setLoading] = useState('refunds')
    const [modal, setModal] = useState(false)
    const myRef = React.useRef(null)
    useEffect(() => {
        getRefunds()
        return () => {

        }
    }, [])

    const getRefunds = async () => {
        const response = await getToApi('refund/getall')
        if (response.refunds) setRefunds(response.refunds)
        else toastify('error', 'impossible de recuperer les reclammations')
        setLoading('')
    }
    const select = (item) => {
        setRefund(item)
        setModal(true)
    }

    return (
        <div className='refunds shadows' ref={myRef}>
            <h2>Liste des Remboursements</h2>
            <div className="refunds-liste">
                <div className="transaction-min">
                    <div><b>Date</b></div>
                    <div><b>compte</b></div>
                    <div><b>Montant </b></div>
                    <div><b>Auteur</b></div>
                    <div><b>Transaction</b></div>
                    {/*  <div><b>Statut</b></div>
                    <div><b>Actions</b></div> */}
                </div>
                {

                    loading == 'refunds' ? <>
                        <Skel variant="rectangular" heigth={100} /><Skel variant="rectangular" heigth={100} /><Skel variant="rectangular" heigth={100} />
                    </>
                        : (refunds.length ? refunds.map((item, i) => <RefundMin data={item} action={select} key={`refund-${i}`} />)
                            : <center><br /><h2>Aucun Remboursement effectué</h2><br /></center>)
                }
            </div>

            <Modal open={modal} center onClose={() => setModal(false)} container={myRef.current} >
                <RefundDetails data={refund} />
            </Modal>
        </div>
    )
}

function RefundMin({ data, action }) {
    return (
        <div className="transaction-min refund-min" onClick={() => action(data)}>
            <div><b>{dateFormat(data.created_at, "dd/mm/yyyy, HH:MM")}</b></div>
            <div>{data.account}</div>
            <div>{`${data.amount} XAF`} </div>
            <div>{data.authorName}</div>
            <div className='tx-id'  >{data.transaction_id || "R.A.S"}</div>
            {/* <div>{data.status.toUpperCase()}</div>
            <div><button className='details' onClick={() => action(data)}>details</button></div> */}
        </div>

    )
}

const RefundDetails = ({ data }) => {
    const history = useHistory()
    return (
        <div className="handle-refund">
            <div className="refund-operation">
                <h3>Details du Remboursement</h3>
                <div>Identifiant (preuve) &ensp;:&ensp; &ensp; <b>{data.refund_proof}</b> </div>
                <div>Identifiant de l'operation &ensp;:&ensp; &ensp;
                    {data.transaction_id.includes('null') ? "Non lié à une operation"
                        : <span className='tx-id' onClick={() => history.push('/transaction/:' + data.transaction_id)}>{data.transaction_id}</span> || "R.A.S"}
                </div>
                <div>Compte utilisé &ensp;:&ensp; &ensp; {data.account} </div>
                <div>Montant en XAF &ensp;:&ensp; &ensp; {data.amount} </div>
                <div>Type d'operation &ensp;:&ensp; &ensp; {detectOperation(data.transaction_id) || "Non lié à une operation"} </div>
                <div>Objectif &ensp;:&ensp; &ensp; {data.purpose} </div>
                <div>Commentaire &ensp;:&ensp; &ensp; <br />{data.comment} </div>
                <div>Effectué par &ensp;:&ensp; &ensp; {data.authorName} </div>
                <div>Effectué le &ensp;:&ensp; &ensp; {dateFormat(data.created_at, 'dd/mm/yyyy, HH:MM')} </div>
            </div>
        </div>
    )
}

export default Refunds